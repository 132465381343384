import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { styled } from "linaria/react"
import { colors } from "../theme"
import Img from "gatsby-image"
import Form from "../Elements/SubForm"

const Wrapper = styled.section`
  @media screen and (max-width: 480px) {
    padding: 25px 15px;
  }
  @media screen and (max-width: 900px) and (min-width: 481px) {
    flex-direction: column;
    padding: 25px 30px;
  }
  @media screen and (max-width: 1280px) and (min-width: 901px) {
    padding: 15px 0px 50px 30px;
  }
  padding: 55px 25px 85px 55px;
  background: ${colors.mainPink};
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 5;
  display: flex;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.15);
`
const TextContainer = styled.div`
  @media screen and (max-width: 900px) {
    width: 100%;
    max-width: none;
  }
  max-width: 470px;
  width: 45%;
  > h2 {
    @media screen and (max-width: 480px) {
      font-size: 30px;
    }
    font-size: 35px;
    font-weight: 300;
    margin-bottom: 20px;
    text-transform: uppercase;
    b {
      font-weight: 600;
    }
  }
  > p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 55px;
    font-weight: 300;
  }
`
const ImageWrapper = styled.div`
  @media screen and (max-width: 900px) {
    display: none;
  }
  position: absolute;
  width: 45%;
  max-height: 467px;
  right: 16px;
  top: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`

const StyledImg = styled(Img)`
  @media screen and (max-width: 1024px) {
    min-height: 400px;
  }
`

const Subscription = props => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "subscribe.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, maxHeight: 467) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const header = props.header || (
    <React.Fragment>
      Get notified when I<b> Post something new.</b>
    </React.Fragment>
  )

  const body = props.body || (
    <React.Fragment>
      Get The Latest Updates About Our Mission, Ways to Support and
      Notifications When I Post Interesting Things.
    </React.Fragment>
  )

  return (
    <Wrapper style={props.style}>
      <TextContainer>
        <h2>{header}</h2>
        <p>{body}</p>
        {/* Id of the klaviyo newsletter to subscribe to*/}
        <Form id="XyuQ7T" />
      </TextContainer>
      <ImageWrapper>
        <StyledImg
          imgStyle={{ objectFit: "cover" }}
          fluid={data.file.childImageSharp.fluid}
        />
      </ImageWrapper>
    </Wrapper>
  )
}

export default Subscription
