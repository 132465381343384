import React, { useEffect, useState } from "react"
import { styled } from "linaria/react"

import Post from "../Elements/SocialPost"
import Social from "../Elements/Social"
import { colors } from "../theme"

const Feed = styled.section`
  @media screen and (max-width: 480px) {
    margin: 100px 0;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 250px 0;
`
const PostWrapper = styled.div`
  @media screen and (max-width: 480px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }
  @media screen and (max-width: 900px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    padding-bottom: 10px;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
`
const Top = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.div`
  @media screen and (max-width: 900px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
    height: 55px;
  }
  @media screen and (max-width: 390px) {
    font-size: 16px;
    height: 50px;
  }
  background: ${colors.black};
  padding-left: 55px;
  padding-right: 55px;
  height: 78px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
    @media screen and (max-width: 390px) {
      font-size: 14px;
    }
    font-size: 35px;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
    color: #fff;
  }
`
const VerticalDivider = styled.div`
  @media screen and (max-width: 480px) {
    height: 40px;
  }
  width: 1px;
  background: ${colors.black};
  margin: 0 10px;
  height: 50px;
`
const Follow = styled.div`
  @media screen and (max-width: 480px) {
    span {
      display: none;
    }
  }
  display: flex;
  flex-direction: column;
  span {
    font-size: 12px;
    text-transform: uppercase;
  }
`

const SocialFeed = () => {
  const [posts, setPosts] = useState()
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  }

  useEffect(() => {
    fetch("https://nocturnaldevelopment.org/getFeed/", requestOptions)
      .then(response => response.json())
      .then(result => {
        setPosts(
          result.map((post, index) => {
            if (index < 3) {
              return (
                <Post
                  key={index}
                  image={post.media_url}
                  caption={post.caption}
                  link={post.permalink}
                />
              )
            } else return null
          })
        )
      })
      .catch(error => console.log("error", error))
  }, [])
  if (posts) {
    return (
      <Feed>
        <Top>
          <Title>
            <h2>Latest From Me</h2>
          </Title>
          <VerticalDivider />
          <Follow>
            <span>Follow Me</span>
            <Social height="24px" width="24px" />
          </Follow>
        </Top>
        <PostWrapper>{posts}</PostWrapper>
      </Feed>
    )
  } else return <div style={{ padding: "50px" }} />
}

export default SocialFeed
