import React, { useState } from "react"
import { styled } from "linaria/react"

const PostWrapper = styled.div`
  @media screen and (max-width: 900px) {
    min-width: 60%;
    min-height: 0;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    min-width: 90%;
    max-height: 400px;
    min-height: 0;
  }
  position: relative;
  max-height: 540px;
  min-height: 360px;
  overflow: hidden;
  width: 100%;
  display: flex;
  &:hover {
    > img {
      filter: saturate(0%) brightness(50%);
    }
  }
`

const Popup = styled.div`
  font-weight: 300;
  text-transform: capitalize;
  display: flex;
  font-size: 16px;
  transition: all 0.2s ease-out;
  transition-delay: 0.1s;
  position: absolute;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  margin: auto;
  color: #fff;
  padding: 20px;
  text-align: center;
  > a {
    margin: 35px;
    text-decoration: none;
    color: #fff;
  }
  > span {
    position: relative;
    line-height: 26px;
    height: 130px;
    overflow: hidden;
    margin: auto auto 7% auto;
    font-weight: 500;
    text-overflow: ellipsis;
  }
  &.active {
    visibility: visible;
    transform: translateY(0);
  }
`

const Gradient = styled.div`
  transition: all 0.3s ease-out;
  position: absolute;
  visibility: hidden;
  width: 100%;
  height: 0%;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.671875) 75.17%,
    #000000 100%
  );
  &.active {
    visibility: visible;
    height: 100%;
  }
`

const StyledImage = styled.img`
  object-fit: cover;
  transition: filter 0.4s ease-in-out;
  height: auto;
  width: 100%;
  background: #333;
`

const SocialPost = props => {
  const [active, setActive] = useState(false)
  let cropStyle = {}
  if (props.link === "https://www.instagram.com/p/CG3euQXgqT8/") {
    cropStyle = { transform: "scale(1.25)" }
  }
  return (
    <PostWrapper
      onMouseEnter={() => setActive(!active)}
      onMouseLeave={() => setActive(!active)}
    >
      <StyledImage
        src={props.image}
        style={cropStyle}
        alt="instagram post from Katelyn Fedrick"
      />
      <Gradient className={active ? "active" : ""} />
      <Popup className={active ? "active" : ""}>
        <span>{props.caption}</span>
        <a href={props.link}>View Post On Instagram</a>
      </Popup>
    </PostWrapper>
  )
}

export default SocialPost
